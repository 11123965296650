html,
body {
  padding: 0;
  margin: 0;
  font-family: TTHoves, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: black;
    background: white;
  }
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: #141414;
  }
}

@font-face {
  font-family: "TTHoves";
  src: url("/fonts/TTHoves-DemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("/fonts/TTHoves-Light.ttf");
  font-weight: 300;
  font-display: swap;
}